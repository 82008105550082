//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BaseExtend from "@/components/Base/Mixin/BaseExtend";
import EmailMessages from "@/components/Nylas/EmailMessages";
import TemplateEmailForm from "@/components/Unlayer/TemplateEmailForm";
import functions from "@/functions";
import Mustache from "mustache";
import {mapState} from "vuex";
import PdfTools from "@/components/PDFgenerator/PdfTools";
import ListValues from "@/components/Mixin/ListValues";

export default {
  name: "BaseSectionEmails",
  components: { EmailMessages, TemplateEmailForm },
  mixins: [ BaseExtend ],
  props: {
    formData : {},
    label: null,
    name: null,
    section: {},
    emailField: null,
    threadIDField: null,
    showTemplateEmailForm: {},
  },
  data() {
    return {
      emailTypes: [
        {
          id: 0,
          type: 'thread',
          name: 'All emails with work order reference',
        },
        {
          id: 1,
          type: 'account',
          name: 'All emails to this account',
        },
      ],
      choosedEmailTypeId: 0,
    };
  },
  computed: {
    ...mapState('settings', ["userEmailSettings"]),
    threadID() {
      return this.choosedEmailTypeId === 0 ? this.formData.data[this.threadIDField] : '';
    },
    emailCustomer() {
      return this.formData.data[this.emailField];
    },
    emailToFields() {
      return ListValues.getEmailAddresses(this.section.emailToFields, this.docData);
    },
    emailCC_Fields() {
      return ListValues.getEmailAddresses(this.section.emailCC_Fields, this.docData);
    },
    emailBCC_Fields() {
      return ListValues.getEmailAddresses(this.section.emailBCC_Fields, this.docData);
    },
    emailSubject() {
      if(this.section.emailSubject){
        return Mustache.render(this.section.emailSubject, this.docData);
      }
      return "";
    },
    isShowTemplateEmailForm: {
      get() {
        return this.showTemplateEmailForm ? this.showTemplateEmailForm.value : false;
      },
      set() {
        this.showTemplateEmailForm.value = false;
      }
    },
    docData() {
      return PdfTools.getInputJsonForPDF(this.formData);
    }
  }
};
