//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableDragger from "table-dragger";

export default {
  name: "ListViewSetting",
  props: {
    section: null,
    allColumnsForSelect: null,
    defVisibleColumns: null,
  },
  data() {
    return {
      columnSortEnabled: false,
      visibleColumns: this.defVisibleColumns,
      draggerHeaders: null,
    };
  },
  methods: {
    saveColumnSettings() {
      this.$emit("setColumns", this.visibleColumns);
    },
    doTableHeadersDragable(enabled) {
      if (this.draggerHeaders !== null) {
        this.draggerHeaders.destroy();
      }
      if (enabled) {
        const div = document.getElementsByClassName('table-column-order-' + this.section.name)[0];
        const el = div.getElementsByClassName('q-table')[0];

        this.draggerHeaders = tableDragger(el, {
          mode: 'column',
          dragHandler: 'th',
          onlyBody: true,
        });

        this.draggerHeaders.on('drop', (from, to) => {
          // console.log(this.section);
          this.$emit("onColumnDrop", {from, to});
        });
      }
    },
  },
  watch: {
    columnSortEnabled() {
      this.doTableHeadersDragable(this.columnSortEnabled);
    },
  }
};
