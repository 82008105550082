







































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Action, State} from "vuex-class";
import {mapGetters} from "vuex";

import store from "./../../store";
import functions from './../../functions';
import Mustache from "mustache";

import EmailAttachments from "@/components/Nylas/EmailAttachments.vue";
import {asyncForEach} from "@/common/helpers/async-for-each";
import unlayerTools, {UnlayerSettings} from '@/components/Unlayer/UnlayerTools';
import PdfTools from '@/components/PDFgenerator/PdfTools';
import UnlayerTools from "@/components/Unlayer/UnlayerTools";
import ListValues from "@/components/Mixin/ListValues";

@Component({
  computed: {
    ...mapGetters("user", {profile: "profile"}),
  },
  components: {
    EmailAttachments,
  }
})

export default class TemplateEmailForm extends Vue {
  @Prop(Object) formData;
  @Prop(Object) emailSettings;
  @Action('uploadFileToStorage') uploadFileToStorage;
  @Action('deleteFileFromStorage') deleteFileFromStorage;
  @State("userProfile") profile;
  @Prop(Array) emailToFields;
  @Prop(Array) emailCC_Fields;
  @Prop(Array) emailBCC_Fields;
  @Prop(String) emailSubject;
  emailData = {
    to: '',
    from: '',
    subject: '',
    cc: '',
    bcc: '',
    message: '',
    fileIDs: []
  };
  data: any = {};
  editor: any;
  design = {name: '', id: '', design: {}, sublect: '', to: '', cc: '', bcc: ''};
  userDesigns = [];
  mergeTags = {};
  settings = new UnlayerSettings();
  showCcField = false;
  showBccField = false;
  loading = false;
  attachLoading = false;
  sizeOver = false;
  askCloseFormModal = false;

  get docData() {
    return this.formData ? this.formData.data : {};
  }

  get loadingData() {
    return this.loading || this.attachLoading;
  }

  get notSend() {
    return this.loadingData || this.sizeOver;
  }

  async mounted() {
    this.data = PdfTools.getInputJsonForPDF(this.formData);
    this.emailData.from = this.profile.email;
    this.emailData.to = this.emailToFields.join(', ');
    this.emailData.cc = this.emailCC_Fields.join(', ');
    this.emailData.bcc = this.emailBCC_Fields.join(', ');
    this.emailData.subject = this.emailSubject;
    await this.getUserDesigns();
    await this.getSettings();
    this.unlayerInit();
  }

  unlayerInit() {
    /* eslint-disable */
    // @ts-ignore: Unreachable code error
    this.editor = unlayer.createEditor({
      id: 'editor-container',
    })
  }

  setTemplateSetting(design) {
    this.emailData.to = ListValues.getEmailAddresses(design.to, this.data);
    this.emailData.cc = ListValues.getEmailAddresses(design.cc, this.data);
    this.emailData.bcc = ListValues.getEmailAddresses(design.bcc, this.data);
    if(design.subject){
      this.emailData.subject = Mustache.render(design.subject, this.data);
    }
  }

  async loadDesign() {
    this.setTemplateSetting(this.design);
    await this.editor.loadDesign(this.design.design);
    this.exportHtml();
  }

  async getUserDesigns() {
    const tenantTemplates: any = await unlayerTools.getTenantTemplates();
    const userTemplates: any = await unlayerTools.getUserTemplates();
    this.userDesigns = [].concat(userTemplates, tenantTemplates).filter((d: any) => d.module === this.formData.module);
  }

  async getSettings() {
    (this.settings as any) = await UnlayerTools.getSettings();
  }

  exportHtml() {
    this.editor.exportHtml(
        (data) => {
          //console.log(data);
          //console.log(this.data);
          this.emailData.message = Mustache.render(data.html, this.data);
        }
    )
  }

  onReceiveResetShowForm() {
    this.askCloseFormModal = true;
  }

  onCloseFormAfterSure() {
    this.$emit("receiveResetShowForm");
  }

  get emailSender() {
    return this.emailSettings ? this.emailSettings.id : null;
  }

  async removedFile(data) {
    const removedFile = data.removedFile;
    this.sizeOver = data.sizeOver;
    this.emailData.fileIDs = this.emailData.fileIDs.filter((f: any) => f.fireID !== removedFile.ID);
  }

  async addFiles(data) {
    const files = data.files;
    const addFile = data.addFile;
    this.sizeOver = data.sizeOver;
    if (this.sizeOver) {
      store.state.alertMessage = 'over_size';
      return;
    }
    this.attachLoading = true;
    let nylasFiles = {data: []};

    if (files) {
      // from dropzone
      const fireFiles = await this.uploadToFirestore(files);
      nylasFiles = await this.uploadToNylas(fireFiles);
      await this.removeFromFirestore(files);
    }

    if (addFile) {
      // from attachment or media
      nylasFiles = await this.uploadToNylas([addFile]);
    }

    const filesIds = nylasFiles.data;
    (this.emailData.fileIDs as any) = [...this.emailData.fileIDs, ...filesIds];
    this.attachLoading = false;
  }

  async uploadToFirestore(files) {
    const fireFiles = [];
    await asyncForEach(files, async (file) => {
      file.storageRef = 'email_temp_attachments/';
      //TODO show progress
      file.procentShowCallBack = (snapshot) => {
        const loaded = Math.min(snapshot.totalBytes, snapshot.bytesTransferred)
        //console.log(loaded);
        //this.uploadedSize += loaded - file.__uploaded
        //this.updateFile(file, 'uploading', loaded)
      };
      const uploadedFile = await this.uploadFileToStorage(file);
      (fireFiles as any).push(uploadedFile);
      file.ID = uploadedFile.ID;
    });
    return fireFiles;
  }

  async uploadToNylas(files) {

    // TODO dev mode
    //functions.useFunctionsEmulator("http://localhost:5001");

    const uploadFilesToNylas = functions.httpsCallable('uploadFilesToNylas');

    const filesToSend = files.map(f => {
      //console.log(f);
      const httpsReference = store.state.storage.refFromURL(f.downloadableURL);
        return {
          fireID: f.ID,
          bucket: httpsReference.bucket,
          filePath: httpsReference.location.path_,
          fileName: f.fileName,
          contentType: f.fileType, // fileType , fileName, fileSize
      }});

    const data = {
      'access_token': this.emailSettings.nylasAccessToken,
      files: filesToSend
    };
    const res = await uploadFilesToNylas(data);
    return res;
  }

  async removeFromFirestore(files) {
    await asyncForEach(files, async (file) => {
      this.deleteFileFromStorage(
        `email_temp_attachments/${file.ID}`
      );
    });
  }

  sendEmail() {
    if (!this.emailSettings) {
      alert('Please configure your email settings');
      return;
    }
    this.loading = true;
    // TODO dev mode
    //functions.useFunctionsEmulator("http://localhost:5001");

    const sendMessage = functions.httpsCallable('sendMessage');
    const attachFilesIds = (this.emailData.fileIDs as any).map(n => n.nylasID);
    const options = {
      to: this.emailData.to
        .split(',')
        .map(el => { return { email: el.trim() }; }),

      cc: this.emailData.cc
        .split(',')
        .map(el => { return { email: el.trim() }; }),
      bcc: this.emailData.bcc
        .split(',')
        .map(el => { return { email: el.trim() }; }),

      subject: this.emailData.subject,
      body: this.emailData.message,
      // eslint-disable-next-line @typescript-eslint/camelcase
      files: attachFilesIds,
    };

    if(this.data.threadID && this.data.replyToMessageID) {
      options['replyToMessageId'] = this.data.replyToMessageID;
    }

    sendMessage({
      accessToken: this.emailSettings.nylasAccessToken,
      options
    }).then((res: any) => {
      //TODO move it to the workOrder

      /*
      const firePath = "tenants/" + store.state.tenantID + "/modules" + "/workOrders" + "/records";
      db
        .collection(firePath)
        .doc(this.formData.ID)
        .update({ threadID: res.data.threadId, replyToMessageID: res.data.replyToMessageId })
        .then(() => console.log('threadID was saved to WorkOrder'))
        .catch(() => console.log('threadID wasn\'t saved to WorkOrder'));
       */

      this.loading = false;
      // reset data
      this.emailData.fileIDs = [];
      this.$emit("receiveResetShowForm");
    }).catch(error => {
      console.log(error);
      this.$store.dispatch('showAlertMessage', 'error');
      this.loading = false;
    });
  }
}
