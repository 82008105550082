//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BaseExtend from "@/components/Base/Mixin/BaseExtend";

import {mapActions} from 'vuex';
import {asyncForEach} from "@/common/helpers/async-for-each";
import QFirebaseUploaderBase from "@/components/Parts/QFirebaseUploaderBase.vue";
import Attachments from "@/components/Parts/Attachments.vue";
import {EventBus} from "@/event-bus";
import store from "@/store";

export default {
  name: "BaseSectionAttachment",
  components: {
    Attachments, QFirebaseUploaderBase
  },
  mixins: [ BaseExtend ],
  data() {
    return {
      updateProgress: {}
    };
  },
  methods: {
    ...mapActions(['uploadFileToStorage']),
    ...mapActions(['deleteFileFromStorage']),

    async onSave(needCallbackAlert = true, newAttachments = []) {
      this.formData.noCloseModalForm = true;
      if (this.formData.data.ID) {
        await this.uploadFilesToFirestore(newAttachments);
      } else {
        this.formData.callbackAfterSave = async (id) => {
          this.formData.data.ID = id;
          await this.uploadFilesToFirestore(newAttachments);
          this.formData.callbackAfterSave = null;
          this.formData.noCloseModalForm = true;
          this.saveTheForm(false);
        }
      }
      this.saveTheForm(needCallbackAlert);
    },

    async uploadFilesToFirestore(newAttachments) {
      await asyncForEach(newAttachments, async (file) => {
        file.storageRef = `${this.formData.module}/records/${this.formData.data.ID}/attachments/`;
        //TODO show progress
        file.procentShowCallBack = (snapshot) => {
          const loaded = Math.min(snapshot.totalBytes, snapshot.bytesTransferred)
          //console.log(loaded);
          //this.uploadedSize += loaded - file.__uploaded
          //this.updateFile(file, 'uploading', loaded)
        };
        const uploadedFile = await this.uploadFileToStorage(file);
        this.formData.data[this.name].push(uploadedFile);
      });
    },

    async addAttachments(attachments) {
      await this.onSave(false, attachments.data);
    },

    editAttachments(attachments) {
      this.formData.data[this.name] = attachments.data;
      this.onSave(false);
    },

    removeAttachment(attachment) {
      this.formData.data[this.name] = this.formData.data[this.name].filter(
        (el) => el.ID !== attachment.data.ID
      );
      this.deleteFileFromStorage(
        `${this.formData.module}/records/${this.formData.data.ID}/attachments/${attachment.data.ID}`
      );
      this.onSave(false);
    },

    saveTheForm(needCallbackAlert = true) {
      this.$emit('saveTheForm', needCallbackAlert);
    }
  },
  computed: {
    attachments() {
      return this.formData.data[this.name];
    }
  },
  async beforeMount() {
    EventBus.$on('needToSavePDFToAttachment', async (file) => {
      const attachments = {
        data: [file]
      }
      await this.addAttachments(attachments);
      store.state.alertMessage = "update";
    });
  }
};
