import { render, staticRenderFns } from "./TemplateEmailForm.vue?vue&type=template&id=2da6ee82&"
import script from "./TemplateEmailForm.vue?vue&type=script&lang=ts&"
export * from "./TemplateEmailForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QForm,QCard,QCardSection,QSpace,QBtn,QAvatar,QIcon,QSelect,QSeparator,QInput,QEditor,QSpinner,QDialog,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QForm,QCard,QCardSection,QSpace,QBtn,QAvatar,QIcon,QSelect,QSeparator,QInput,QEditor,QSpinner,QDialog,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
