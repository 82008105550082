//
//
//
//
//
//

import { QFormBuilder } from './index.js'
import AbModel from "@/components/Models/AbModel";
import * as fieldsMapping from './fieldsMapping'
import FormDataTools from "@/components/Mixin/FormDataTools";
import {mapState} from "vuex";

export default {
  name: 'BuilderPage',
  components: {
    QFormBuilder
  },
  props: {
    dynModuleName: null
  },
  data: function () {
    return {
      formData: {
        fields: [],
        unSectionsFields: [],
        sections: [],
        dynamicFields: {},
        dynamicSections: {},
        data: {},
        module: {},
      },
    }
  },
  methods: {
    async load() {
      const module = this.$route.params.moduleName;

      const tools = new FormDataTools(module);
      await tools.load();
      this.formData.module = tools.model;

      this.formData.data = tools.getFormData().data;

      const model = new AbModel();
      model.setDynModuleName(module);
      await model.getModuleFieldsInfo();

      const fields = model.dynFields();
      const formFields = [];

      const dropDownValues = model.dropDownValues;

      for (const key in fields) {
        const newField = Object.assign(fieldsMapping.defaultFormField(), fields[key]);
        const type = fieldsMapping.getSnakeCaseFromCamelFieldType(newField.type);
        if (!type) {
          this.formData.unSectionsFields.push(Object.assign(fieldsMapping.defaultDbEditableField(), fields[key]));
          continue;
        }
        newField.type = type;
        const dropDownOptions = typeof dropDownValues[key] !== 'undefined' ? dropDownValues[key] : [];
        newField.dropdown = fieldsMapping.formOptionsFromDbArray(dropDownOptions);
        if (type === fieldsMapping.SNAKE_RELATED_FIELD) {
          if (newField.query === undefined) {
            newField.query = [];
          }
          const tmpQuery = [];
          newField.query.forEach(q => {
            tmpQuery.push(Object.assign({...fieldsMapping.defaultNewRelatedToFieldQueryRow()}, q));
          });
          newField.query = tmpQuery;
          const relatedFieldCopyArr = newField.relatedFieldCopy !== undefined ? Object.keys(newField.relatedFieldCopy) : [];
          newField.relatedFieldCopy = fieldsMapping.formOptionsFromDbArray(relatedFieldCopyArr);
        }
        formFields.push(newField);
      }

      const dynSections = model.dynSections();
      const dynSectionsSorted = [];
      const fieldsGenerationsArray = [];
      const listsIndexMapping = [];
      Object
        .keys(dynSections).sort(function (a, b) {
        return dynSections[a].order - dynSections[b].order;
      })
        .forEach(function (key) {
          const currentSection = Object.assign(fieldsMapping.defaultSection(), dynSections[key]);
          currentSection.groups = fieldsMapping.formOptionsFromDbArray(currentSection.groups);
          dynSectionsSorted.push(currentSection);
          fieldsGenerationsArray.push(fieldsMapping.getEmptySections());
          listsIndexMapping[currentSection.order - 1] = currentSection.name;
        });

      formFields.forEach(element => {
        const listIndex = listsIndexMapping.indexOf(element.section);
        if (listIndex < 0) {
          this.formData.unSectionsFields.push(element);
          console.log(element)
          return false;
        }
        fieldsGenerationsArray[listIndex][fieldsMapping.getStringColumnVal(element)].push(element);
      });

      fieldsGenerationsArray.forEach(tmp => {
        for (const tmpKey in tmp) {
          if (tmp[tmpKey].length) {
            tmp[tmpKey].sort(this.compare).forEach((field, ind) => {
              field.order = ind + 1
            })
          }
        }
      })

      this.formData.sections = dynSectionsSorted;
      this.formData.fields = fieldsGenerationsArray;
    },
    compare(a, b) {
      if (a.order < b.order)
        return -1;
      if (a.order > b.order)
        return 1;
      return 0;
    }
  },
  mounted() {
    if (this.tenantID) {
      this.load();
    }
  },
  watch: {
    tenantID() {
      return this.load();
    }
  },
  computed: {
    ...mapState(["tenantID"]),
  }
}
