import ListValues from "@/components/Mixin/ListValues";
import FormDataTools from "@/components/Mixin/FormDataTools";

export default class PdfTools {

  static async getFormDataForPDF(module, ID = '') {
    const tools = new FormDataTools(module);
    await tools.load(ID);
    const formData = tools.getFormData();
    return formData;
  }

  static getInputJsonForPDF(formData, mediaFieldName = '', mediaData = []) {

    let tData = ListValues.convertToObject(formData.data, 'ISO-8601');

    // filter media
    if (!mediaFieldName) {
      for (const df in formData.dynamicSections) {
        if (formData.dynamicSections[df].type === 'media') {
          mediaFieldName = df;
          mediaData = formData.data[df].slice();
        }
      }
    }

    if (mediaFieldName) {
      tData[mediaFieldName] = mediaData.slice();
      tData[mediaFieldName + 1] = tData[mediaFieldName].filter(m => m.stage == 1);
      tData[mediaFieldName + 2] = tData[mediaFieldName].filter(m => m.stage == 2);
      tData[mediaFieldName + 3] = tData[mediaFieldName].filter(m => m.stage == 3);
    }
    tData = ListValues.convertToObject(tData, 'ISO-8601');
    tData = ListValues.transformDiv10k(tData, formData);

    //console.log(tData);
    return tData;
  }

}
