import QFormBuilder from './QFormBuilder'
import * as utils from './utils'

export {
  QFormBuilder,
  utils
}

export default {
  QFormBuilder
}
