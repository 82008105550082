import store from "@/store";
import db from "@/db";
import axios from "axios";

export class UnlayerSettings {
  apiKey = '';
  displayMode = 'email';
  projectId = 0;
  locale = -'';
  appearance = {
    theme: 'light',
    panels: {
      tools: {
        dock: 'right'
      }
    }
  };
  user = {};
  tools = {};
  blocks = [];
  editor = {};
  safeHtml = true;
  customJS = [];
  customCSS = [];
}

export default class UnlayerTools {

  static async getGlobalTemplates() {
    const globalTemplates = [];
    const firePath = "/publicLibrary/emailTemplates/records";
    const docs = await db.collection(firePath).get();
    docs.forEach((doc) => {
      if (doc.data()) {
        const item = doc.data();
        item.ID = doc.id;
        (globalTemplates as any).push(item);
      }
    });
    return globalTemplates.map((t: any) => {
      t.type = 'global';
      return t;
    });
  }

  static async getTenantTemplates() {
    const userID = 'tenant';
    const firePath =
      "tenants/" + store.state.tenantID +
      "/users/" + userID +
      "/settings/" + 'unlayer' + "/designs";
    const docs = await db.collection(firePath).get();

    const tenantTemplates = [];
    docs.forEach((doc) => {
      if (doc.data()) {
        const item = doc.data();
        item.ID = doc.id;
        (tenantTemplates as any).push(item);
      }
    });
    return tenantTemplates.map((t: any) => {
      t.type = 'tenant';
      return t;
    });
  }

  static async getUserTemplates() {
    const userID = store.state.userID;
    const firePath =
        "tenants/" + store.state.tenantID +
        "/users/" + userID +
        "/settings/" + 'unlayer' + "/designs";
    const docs = await db.collection(firePath).get();

    const userTemplates = [];
    docs.forEach((doc) => {
      if (doc.data()) {
        const item: any = doc.data();
        item.ID = doc.id;
        (userTemplates as any).push(item);
      }
    });
    return userTemplates.map((t: any) => {
      t.type = 'user';
      t.userID = userID;
      return t;
    });
  }

  static async getSettings() {
    return new UnlayerSettings();
  }

  static async saveTemplate(currentDesign) {

    const firePath =
        "tenants/" + store.state.tenantID +
        "/users/" + currentDesign.userID +
        "/settings/" + 'unlayer' + "/designs";

    if (currentDesign.ID) {
      return await db.collection(firePath).doc(currentDesign.ID).update(currentDesign);
    } else {
      const newDoc = db.collection(firePath).doc();
      currentDesign.ID = newDoc.id;
      return await newDoc.set(currentDesign);
    }
  }

  static async deleteTemplate(currentDesign) {

    const firePath =
        "tenants/" + store.state.tenantID +
        "/users/" + currentDesign.userID +
        "/settings/" + 'unlayer' + "/designs";

    if (currentDesign.ID) {
      console.log(db.collection(firePath).doc(currentDesign.ID).delete());
      return await db.collection(firePath).doc(currentDesign.ID).delete();
    }
  }

}

