//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  QInput,
  QSelect,
  QToggle,
} from 'quasar'

import EditableDropdownOptions from './EditableDropdownOptions'

export default {
  name: 'EditableModuleOptions',
  // eslint-disable-next-line vue/no-unused-components
  components: { QInput, QSelect, QToggle, EditableDropdownOptions },
  props: ['value', 'orderOptions'],
  data() {
    return {
    }
  },
  methods: {

  },
  computed: {

  },
}

