//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BaseExtend from "@/components/Base/Mixin/BaseExtend";
import {date} from "quasar";
import firebase from "@/firebase";
import store from "./../../store";

export default {
  name: "BaseFieldDate",
  components: {},
  mixins: [ BaseExtend ],
  data() {
    return {
      dateTimeMask: store.state.dateTimeMask
    };
  },
  methods: {

  },
  computed: {
    model: {
      get() {
        return this.toDateValue(this.value);
      },
      set(val) {
        if (!val) {
          val = null;
        } else {
          val = this.toTimestampValue(val);
        }
        this.$emit('input', val);
      },
    },
  },
};
