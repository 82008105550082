














import {Component, Prop, Vue} from "vue-property-decorator";
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  import functions from "@/functions";
  import {State} from "vuex-class";
import store from "@/store";

  @Component({
    components: {
      vueDropzone: vue2Dropzone,
    },
  })
  export default class Dropzone extends Vue {
    @State("settings/userEmailSettings") userEmailSettings;
    dropzoneOptions = {
      url: '#',
      thumbnailWidth: 150,
      maxFilesize: 25,
      uploadMultiple: true,
      addRemoveLinks: true,
      autoProcessQueue: false,
      dictDefaultMessage: this.$t("dropzone.headers.dropFiles"),
      dictRemoveFile: this.$t("dropzone.headers.deleteFile"),
      dictFileTooBig: this.$t("common.messages.attachmentOverSize")
    };
    filesSize = 0;
    maxSize = 10 * 1000000; //Mb
    get sizeOver() {
      return this.filesSize >= this.maxSize;
    }
    get sizeLabel() {
      const mb = this.filesSize / 1000000;
      return mb.toFixed(1) + ' MB'
    }
    async addedFiles(files) {
      files.forEach(f => { this.filesSize += f.size; });
      this.$emit("addFiles", { files, sizeOver: this.sizeOver });
    }
    addToDropZone(addFile, url) {
      this.filesSize += addFile.size;
      // eslint-disable-next-line
      // @ts-ignore: Unreachable code error
      this.$refs.myVueDropzone.manuallyAddFile({ ...addFile}, url);
      this.$emit("addFiles", { addFile, sizeOver: this.sizeOver });
    }
    removedFile(removedFile) {
      this.filesSize -= removedFile.size;
      this.$emit("removedFile", { removedFile, sizeOver: this.sizeOver });
    }
  }
