//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  QInput,
  QSelect,
  QToggle,
} from 'quasar'

import EditableDropdownOptions from './EditableDropdownOptions'
import RelatedFieldOptions from './RelatedFieldOptions'
import * as fieldsMapping from '../fieldsMapping'

export default {
  name: 'EditableElementOptions',
  components: { QInput, QSelect, QToggle, EditableDropdownOptions, RelatedFieldOptions },
  props: ['value', 'typeInfo', 'sections', 'orderOptions'],
  data() {
    return {
    }
  },
  methods: {
    ifType (type) {
      switch (type) {
        case 'related': return this.value.type === fieldsMapping.SNAKE_RELATED_FIELD;
        case 'dropdown': return this.value.type === fieldsMapping.SNAKE_DROP_DOWN_FIELD;
      }
      return false
    }
  },
  computed: {
    sectionsOptions() {
      this.$props.sections.forEach(opt => {
        opt.value = opt.name;
        return opt;
      });
      return this.$props.sections;
    },
    columnsOptions() {
      return [
        {
          label: fieldsMapping.FIRST_COLUMN_LABEL,
          value: 1
        },
        {
          label: fieldsMapping.SECOND_COLUMN_LABEL,
          value: 2
        },
        {
          label: fieldsMapping.FULL_COLUMN_LABEL,
          value: 0
        }
      ]
    },
    typeOptions() {
      return fieldsMapping.getSourceFieldOptions();
    }
  },
}

