//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  QList,
  QItem,
  QItemLabel,
  QItemSection,
  // QCheckbox,
  QInput,
  QBtn,
  QTooltip
} from 'quasar'

const defaultNewItem = () => ({
  checked: false,
  label: ''
})

export default {
  name: 'EditableRadiosOptions',
  components: { QList, QItem, QItemLabel, QItemSection, QInput, QBtn, QTooltip },
  props: ['value', 'dropDownHeader', 'readonly', 'dropDownListProps'],
  data: () => ({
    newItem: defaultNewItem()
  }),
  methods: {
    addNewItem: function () {
      this.value.options.push({
        label: this.newItem.label,
        checked: this.newItem.checked
      })
      if (this.dropDownListProps !== undefined) {
        this.dropDownListProps.forEach((opt, i) => {
          if (this.newItem.label === opt) {
            this.dropDownListProps.splice(i, 1);
          }
        })
        this.newItem = defaultNewItem();
      }
    },
    deleteItem: function (index) {
      const el = this.value.options.splice(index, 1)
      if (this.dropDownListProps !== undefined) {
        this.dropDownListProps.push(el[0].label);
      }
    },
    changeSelected: function (label) {
      this.newItem.checked = this.newItem.label === label
      for (const option of this.value.options) {
        option.checked = option.label === label
      }
    }
  }
}

