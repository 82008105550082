//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BaseExtend from "@/components/Base/Mixin/BaseExtend";

import {mapActions} from 'vuex';
import {asyncForEach} from "@/common/helpers/async-for-each";
import MediaList from "@/components/Parts/MediaList.vue";

export default {
  name: "BaseSectionMedia",
  components: {
    MediaList
  },
  mixins: [BaseExtend],

  data() {
    return {};
  },
  methods: {
    ...mapActions(['uploadFileToStorage']),
    ...mapActions(['deleteFileFromStorage']),

    async onSave(needCallbackAlert = true, newMedia = []) {
      if (this.formData.data.ID) {
        await this.uploadFilesToFirestore(newMedia);
      } else {
        this.formData.callbackAfterSave = async (id) => {
          this.formData.data.ID = id;
          await this.uploadFilesToFirestore(newMedia);
          this.formData.callbackAfterSave = null;
          this.saveTheForm(false);
        }
      }
      this.saveTheForm(needCallbackAlert);
    },

    async uploadFilesToFirestore(newMedia) {
      await asyncForEach(newMedia, async (file) => {
        file.storageRef = `${this.formData.module}/records/${this.formData.data.ID}/media/`;
        //TODO show progress
        file.procentShowCallBack = (snapshot) => {
          //const loaded = Math.min(snapshot.totalBytes, snapshot.bytesTransferred)
          //console.log(loaded);
          //this.uploadedSize += loaded - file.__uploaded
          //this.updateFile(file, 'uploading', loaded)
        };
        const uploadedFile = await this.uploadFileToStorage(file);
        this.formData.data[this.name].push(uploadedFile);
      });
    },

    addMedia(media) {
      //this.formData.data[this.name] = (this.formData.data[this.name] || [])
        //.filter((el) => el.stage !== media.stage)
        //.concat(media.data);
        this.onSave(false, media.data);
    },
    removeMedia(mediaItem) {
      this.formData.data[this.name] = (this.formData.data[this.name] || []).filter(
        (el) => el.ID !== mediaItem.data.ID
      );
      this.deleteFileFromStorage(
        `${this.formData.module}/records/${this.formData.data.ID}/media/${mediaItem.data.ID}`
      );
      this.onSave(false);
    },

    saveTheForm(needCallbackAlert = true) {
      this.$emit('saveTheForm', needCallbackAlert);
    }
  },
  computed: {
    mediaBefore() {
      if (this.formData.data[this.name]) {
        return this.formData.data[this.name].filter((el) => el.stage === "1");
      } else {
        return [];
      }
    },
    mediaDuring() {
      if (this.formData.data[this.name]) {
        return this.formData.data[this.name].filter((el) => el.stage === "2");
      } else {
        return [];
      }
    },
    mediaAfter() {
      if (this.formData.data[this.name]) {
        return this.formData.data[this.name].filter((el) => el.stage === "3");
      } else {
        return [];
      }
    }
  }
};
