
export default {
  name: "BaseSubMixin",
  props: { value: null, options: null, label: null, name: null , formData: null, formName: null,
    relatedModule: null, relatedFieldCopy: null, relatedByField: null, refreshFieldOnChange: null,
    relatedQuery: null, relatedToByQuery: null, relatedToField: null,
    subFormField: null, subRecordField: null},
  methods: {
    getSelectedString() {
      return this.selected.length === 0
        ? ""
        : `${this.selected.length} record${
          this.selected.length > 1 ? "s" : ""
        } ${this.$t("table.misc.selectedOf")} ${this.data.length}`;
    },
    getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
      return `${firstRowIndex}-${endRowIndex} ${this.$t(
        "table.misc.of"
      )} ${totalRowsNumber}`;
    },
  },
  computed: {
    columns() {
      const cols = [];
      const fields = this.dynamicFields;

      for(const column in fields) {
        const newCol = {
          name: column,
          align: this.align,
          label: fields[column] && fields[column].label ? fields[column].label : column,
          order: fields[column] && fields[column].order ? fields[column].order : 0,
          field: column,
        };
        cols.push(newCol);
      }

      const result = cols.sort((a, b) => {
        return a.order - b.order;
      });
      return result;
    },
  },
};
