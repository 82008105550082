//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  QInput,
  QSelect,
  QToggle,
} from 'quasar'

import EditableDropdownOptions from './EditableDropdownOptions'

export default {
  name: 'EditableSectionOptions',
  components: { QInput, QSelect, QToggle, EditableDropdownOptions },
  props: ['value', 'orderOptions'],
  data() {
    return {
    }
  },
  methods: {
    ifType (type) {
      return this.value.type === type
    }
  },
  computed: {

  },
}

