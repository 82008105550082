
import {mapActions, mapState} from "vuex";
import ListValues from "@/components/Mixin/ListValues";
import moment from "moment";

export default {
    data: () => ({
      localFormListPagination: {
        sortBy: "changedAt",
        descending: true,
      },
      oldLocalFormListPagination: {},
    }),
    methods: {
      ...mapActions('settings', ["saveFormListViews", "getFormListViews"]),
      setVisibleFormListColumns(cols) {
        this.saveFormListViews({module: this.moduleNameForFormSetting, data: {columns: cols}}).then(() => {
          this.getFormListViews();
        })
      },
      setFormListPagination(pagination) {
        console.log('save pagination - ', pagination);
        this.saveFormListViews({module: this.moduleNameForFormSetting, data: { pagination: {
            sortBy: pagination.sortBy,
            descending: pagination.descending,
            rowsPerPage: pagination.rowsPerPage
        }}}).then(() => {
          this.getFormListViews();
        })
      },
      onColumnDropForm(pos) {
        pos.from -= 1; pos.to -= 1;
        const columns = this.userFormsSettings[this.moduleNameForFormSetting].columns;
        const val = columns[pos.from];
        columns.splice(pos.from, 1);
        columns.splice(pos.to, 0, val);
        this.setVisibleFormListColumns(columns);
      },
      getAllColumnsForSelect() {
        const cols = [];
        const fields = ListValues.getValidColumns(this.getAllFields());
        for (const column in fields) {
          cols.push({name : column, label: fields[column].label || column});
        }
        return cols.sort(((a, b) => { return a.order - b.order }));
      },
      getAllFields(fields1 = {}, fields2 = {}) {
        return {...this.dynFields, ...fields1, ...fields2};
      },
      onSelected(selected) {
        this.selected = selected;
      },
    },
    watch: {
      selected() {
        this.deleteBtnVisible = this.selected.length > 0;
      },
      localFormListPagination() {
        const pag = this.localFormListPagination;
        if (
          pag.oldRowsPerPage && pag.oldRowsPerPage !== pag.rowsPerPage ||
          pag.oldSortBy && pag.oldSortBy !== pag.sortBy ||
          pag.oldDescending && pag.oldDescending !== pag.descending
        ) {
          this.setFormListPagination(pag);
        }
        pag.oldRowsPerPage = pag.rowsPerPage;
        pag.oldSortBy = pag.sortBy;
        pag.oldDescending = pag.descending;
      },
      storedFormListPagination() {
        if (this.storedFormListPagination) {
          //console.log('spag', this.storedFormListPagination);
          this.localFormListPagination = this.storedFormListPagination;
          this.localFormListPagination.page = 1;
          const pag = this.localFormListPagination;
          pag.oldRowsPerPage = pag.rowsPerPage;
          pag.oldSortBy = pag.sortBy;
          pag.oldDescending = pag.descending;
          //console.log('pag', pag);
        }
      }
    },
  mounted() {
    if (this.storedFormListPagination) {
      //console.log('spag', this.storedFormListPagination);
      this.localFormListPagination = this.storedFormListPagination;
    }
  },
  computed: {
      ...mapState(["dynamicModules"]),
      ...mapState("settings", ["userFormsSettings", "tenantDefaultColumns"]),
      columns() {
        const sortable = !this.columnSortEnabled;
        const cols = [];
        const fields = this.getAllFields();
        const locVisibleColumns = this.visibleFormListColumns;

        //console.log(fields);

        if (locVisibleColumns) {
          locVisibleColumns.filter(c => fields[c]).map((column) => {
            const label =  fields[column].label ? fields[column].label : column;
            const dateType =  fields[column].type === 'date' || fields[column].type === 'datetime';
            const newCol = {
              name: column,
              align: this.align,
              label: label,
              field: column,
            };
            if (sortable) {
              newCol.sortable = sortable;
              if (dateType) {
                newCol.sort = (a, b) => {
                  //console.log(a, moment(a, 'DD-MM-yyyy HH:mm'));
                  const d1 = moment(a, 'DD-MM-yyyy HH:mm');
                  const d2 = moment(b, 'DD-MM-yyyy HH:mm');
                  if (d1.isAfter(d2)) {
                    return 1;
                  } else if (d1.isBefore(d2)) {
                    return -1;
                  }
                  return 0;
                };
              }
            }
            if ((this.dropDownValues && this.dropDownValues[column]) ||
              (fields[column] && fields[column].type === 'checkbox')) {
              newCol.filterType = 'select';
            }
            cols.push(newCol);
          });
        }
        //console.log('cols', cols);
        return cols;
      },
      // get visible columns for BaseSectionRelatedRecords, sub records and product lines
      visibleFormListColumns: {
        get() {
          let  vColumns = this.userFormsSettings[this.moduleNameForFormSetting] && this.userFormsSettings[this.moduleNameForFormSetting].columns
            ? this.userFormsSettings[this.moduleNameForFormSetting].columns
            : [];
          if (!vColumns.length) {
            vColumns = this.tenantDefaultColumns[this.relatedOrLineModule] || [];
          }
          const hiddenFields = []
          for (const f in this.dynFields){
            if (this.dynFields[f].hidden) hiddenFields.push(this.dynFields[f].name);
          }
          vColumns = vColumns.filter(e => !hiddenFields.includes(e));
          //console.log('vColumns', vColumns);
          return vColumns;
        }
      },
      storedFormListPagination() {
        const pagination = this.userFormsSettings[this.moduleNameForFormSetting] && this.userFormsSettings[this.moduleNameForFormSetting].pagination
          ? this.userFormsSettings[this.moduleNameForFormSetting].pagination
          : null;
        return pagination;
      },
      moduleNameForFormSetting() {
        const mName = this.formData.module + '-' + this.section.name;
        console.log('mn', mName);
        return mName;
      },
      module() {
        return this.dynModuleName;
      },
      relatedOrLineModule() {
        return this.lineModule || this.relatedModule;
      },
      dynModuleData() {
        return this.dynamicModules && this.dynamicModules[this.dynModuleName] ? this.dynamicModules[this.dynModuleName] : {} ;
      },
      dynTitle() {
        return this.dynModuleData ? this.dynModuleData.name : null;
      },
      dynFields() {
        return this.dynModuleData && this.dynModuleData.fields
          ? { ...ListValues.locFields(), ...this.dynModuleData.fields}
          : {};
      },
      allColumnsForSelect() {
        return this.getAllColumnsForSelect();
      },
      align() {
        return "left";
      },
    },
  };
