//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BaseExtend from "@/components/Base/Mixin/BaseExtend";

export default {
  name: "BaseFieldDecimal",
  components: {},
  mixins: [ BaseExtend ],
  data() {
    return {
    };
  },
  methods: {

  },
  computed:{
    model: {
      get() {
        return Math.round(this.value * 100);
        //return this.value;
      },
      set(val) {
        if (!val) {
          val = null;
        } else {
          //val =  Number(val.replace(',', '')) * 100;
        }
        this.$emit('input', Number(val));
      },
    },
  },
};
