//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QFirebaseUploaderBase from "./QFirebaseUploaderBase";
import axios from "axios";

export default {
  name: "MediaList",
  props: {
    media: {},
    stage: {},
    label: String,
  },
  components: {
    QFirebaseUploaderBase,
  },
  data() {
    return {
      slide: 0,
      fullscreen: false,
    };
  },
  methods: {
    addMedia(media) {
      const mediaData = media.data.map((el) => {
        el.stage = this.stage;
        return el;
      });
      this.$emit("addMedia", {data: mediaData, stage: this.stage});
    },
    removeItem() {
      this.$emit("removeMedia", { data: this.media[this.slide] });
      this.slide = 0;
    },
    downloadImage(){
      axios({
            url: this.media[this.$refs.carousel.value].downloadableURL,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]));
              const fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', this.media[this.$refs.carousel.value].fileName);
              document.body.appendChild(fileLink);

              fileLink.click();
        });
      }
  },
};
