//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
  name: "Attachments",
  props: {
    attachments: {},
  },
  data() {
    return {
      editableItem: {},
      openEditNameModal: false,
    };
  },
  methods: {
    ...mapActions(["saveWorkOrdersCounter"]),

    goToAttachment(attachment) {
      //console.log(attachment);
      axios({
        url: attachment.downloadableURL,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', attachment.fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
      // TODO need to check how to download file from the storage right. From the firestore function?
      //window.open(attachment.downloadableURL, "_blank");
    },
    toggleEditNameModal() {
      if (!this.editableItem.fileName) {
        return;
      }
      if (this.openEditNameModal) {
        this.editName();
      }
      this.openEditNameModal = !this.openEditNameModal;
    },
    editItem(item) {
      this.editableItem = item;
    },
    editName() {
      const { attachments, editableItem } = this;
      attachments.forEach((attachment) => {
        if (editableItem === attachment.ID) {
          return editableItem;
        }
      });

      this.$emit("editAttachments", { data: attachments });
    },
    removeItem(item) {
      this.$emit("removeAttachment", { data: item });
    },
  },
  computed: {
    ...mapState("subsribe", ["workOrdersCounter"]),
  },
};
