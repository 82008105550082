//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BaseFieldRelated from "@/components/Base/BaseFieldRelated";

export default {
  name: "BaseFieldRelatedMultiSelect",
  mixins: [ BaseFieldRelated ],
  data() {
    return {
      cModel: this.value,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
};
