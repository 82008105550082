import AbSubFormModel from "@/components/Models/AbSubFormModel";
import store from "@/store";
import db from "@/db";
import {asyncForEach} from '@/common/helpers/async-for-each';

class AbSubRecordModel extends AbSubFormModel {
  documentID = ""; // ID document/subcollection
  subRecord = "";

  setMainData(data) {
    this.documentID = data.documentID;
    this.subRecord = data.subRecord;
    if (data.module) {
      this.module = data.module;
    }
  }

  tenant(module: any = null): string {
    const lmodule = module ? module : this.module;
    return "tenants/" + store.state.tenantID + "/modules/" + lmodule + "/records/" + this.documentID + '/' + this.subRecord;
  }

  async getSubRecords() {
    const collection = db.collection(this.tenant());
    const result = await collection.get();
    const subrecords: Array<any> = [];
    result.forEach((doc) => {
      const rec = doc.data();
      subrecords.push(rec);
    });
    return subrecords;
  }

  async delSubRecords() {
    const subRecords = await this.getSubRecords();
    subRecords.forEach(e => {
      this.delete(e.ID);
    })
  }

  delete(id: string, module = "") {
    const doc = db.collection(this.tenant(module)).doc(id);
    return doc.delete();
  }

}

export default AbSubRecordModel;
