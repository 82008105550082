
import {date} from "quasar";
import firebase from "@/firebase";
import {mapActions} from "vuex";
import { EventBus } from "@/event-bus";
import store from "./../../../store";

export default {
  name: "BaseExtend",
  props: {
    value: null, options: null, label: null, name: null , formData: null, formName: null,
    relatedModule: null, relatedFieldCopy: null, relatedByField: null, refreshFieldOnChange: null,
    relatedQuery: null, relatedToByQuery: null, relatedToField: null,
    subFormField: null, subRecordField: null, lineType: null, sectionLabel: null,
    sectionColumns: null, customButtons: null,
    // TODO refactor to the field and section
    field: null, section: null
  },
  components: {},
  data() {
    return {
      noValid: false,
      noValidMessage: '',
      soptions: null,
      copyCurrentValue: null,
      relatedOptions: [],
    };
  },
  methods: {
    ...mapActions("query", ["queryRelatedFieldOptions"]),
    onFieldChange(val) {
      return val;
    },
    filterAutocomplete(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        this.soptions = (this.options || this.relatedOptions).filter(
          (v) => {
            const name = v.name ? v.name : v;
            return String(name).toLocaleLowerCase && String(name).toLocaleLowerCase().indexOf(needle) > -1
          }
        );
      });
    },
    toDateValue(val) {
      if (val && typeof val.toDate === "function") {
        return date.formatDate(val.toDate(), "YYYY-MM-DD");
      }
      if (val && val instanceof Date) {
        return date.formatDate(val, "YYYY-MM-DD");
      }
      return val;
    },
    toDateTimeValue(val) {
      if (val && typeof val.toDate === "function") {
        return date.formatDate(val.toDate(), store.state.dateTimeMask);
      }
      if (val && val instanceof Date) {
        return date.formatDate(val, store.state.dateTimeMask);
      }
      return val;
    },
    toTimestampValue(val) {
      if (store.state.dateTimeMask === "DD-MM-YYYY HH:mm") {
        val = date.extractDate(val, "DD-MM-YYYY HH:mm");
        val = date.formatDate(val, "YYYY-MM-DD HH:mm");
      }
      return firebase.firestore.Timestamp.fromMillis(Date.parse(val));
    },
    validate() {
      const result = this.isValid();
      this.noValid = !result.valid;
      this.noValidMessage = this.noValid ? result.errorMessage : '';
      return result.valid;
    },
    addValidateToForm() {
      this.$emit('addFieldToValidationList', {name: this.name, validate: this.validate});
    },
    isValid() {
      const fieldName = this.name;
      const field = this.formData.dynamicFields[fieldName];
      const errorMessage = 'Please select field';
      const value = this.formData.data[this.name];

      if (field.mandatory) {
        const noValid = value === undefined || value === null || value === '' || (value.hasOwnProperty('ID') && !value.ID);
        if (noValid) {
          const result = {valid: false, errorMessage: field.mandatoryErrorMessage || errorMessage}
          //console.log('mandatory', fieldName, value, result);
          return result;
        }
      }

      if (field.min) {
        if (String(value).length < Number(field.min)) {
          const result = {valid: false, errorMessage : field.minErrorMessage || errorMessage}
          //console.log('min', fieldName, value, result, field.min);
          return result;
        }
      }

      if (field.max) {
        if (String(value).length > Number(field.max)) {
          const result = {valid: false, errorMessage : field.maxErrorMessage || errorMessage}
          //console.log('max', fieldName, value, result, field.max);
          return result;
        }
      }

      return {valid: true, errorMessage : '' };
    },
    refreshAfterCopy() {
      //
    }
  },
  mounted() {
    this.soptions = this.options || this.relatedOptions;
    this.addValidateToForm();
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.onFieldChange(val);
        this.$emit('input', val)
      }
    },
    isDisabled() {
      return this.field ? this.field.disabled : false;
    },
    cLabel() {
      return this.label ? this.label : this.$t(this.formData.model + '.fields.' + this.name);
    },
    hasError() {
      return this.noValid;
    },
  },
  beforeMount() {
    EventBus.$on('needToUpdateFieldValueAfterCopy', async (data) => {
      if (data.name === this.name) {
        this.refreshAfterCopy(data.value);
      }
    });
  }
};
