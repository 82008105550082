//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  QLayout,
  QPageContainer,
  QPage,
  QDrawer,
  QTab,
  QTabs,
  QTabPanel,
  QTabPanels,
  QTooltip,
  QBtn,
  uid,
  extend
} from 'quasar'

import draggable from 'vuedraggable'
import EditableElement from './editable/EditableElement'
import EditableElementOptions from './editable/EditableElementOptions'
import EditableSectionOptions from './editable/EditableSectionOptions'
import EditableModuleOptions from './editable/EditableModuleOptions'
import * as fieldsMapping from './fieldsMapping'

import BaseSectionRelatedRecords from '@/components/Base/BaseSectionRelatedRecords.vue';
import BaseSectionAttachment from "@/components/Base/BaseSectionAttachment.vue";
import BaseSectionMedia from "@/components/Base/BaseSectionMedia.vue";
import BaseSectionSubForm from '@/components/Base/BaseSectionSubForm.vue';
import BaseSectionUsage from '@/components/Base/BaseSectionUsage.vue';
import BaseSectionEmails from '@/components/Base/BaseSectionEmails.vue';

import AbModel from "@/components/Models/AbModel";
import store from "@/store";
import db from "@/db";
import BaseSectionProductLines from "@/components/Base/BaseSectionProductLines";

export default {
  name: 'QFormBuilder',
  components: {
    BaseSectionProductLines,
    EditableElement,
    EditableElementOptions,
    EditableSectionOptions,
    EditableModuleOptions,
    draggable,
    QLayout,
    QPageContainer,
    QPage,
    QDrawer,
    QTab,
    QTabs,
    QTabPanel,
    QTabPanels,
    QTooltip,
    QBtn,

    BaseSectionEmails,
    BaseSectionSubForm, BaseSectionUsage,
    BaseSectionRelatedRecords, BaseSectionAttachment, BaseSectionMedia
  },
  data() {
    return {
      fields: [],
      sections: [],
      drawer: true,
      tab: 'add',
      currentField: false,
      isDragAction: false,
      isClickForCurrentField: false,
      hovered: [],
      currentSection: false,
      currentModule: false,
      isNewSectionSelect: false,
      selectedSectionName: null,
      first: fieldsMapping.FIRST_COLUMN_LABEL,
      second: fieldsMapping.SECOND_COLUMN_LABEL,
      full: fieldsMapping.FULL_COLUMN_LABEL,
      currentListLength: 0,
      sourceFields: fieldsMapping.getSourceFieldOptions(),
      sourceSections: fieldsMapping.getSourceSectionOptions()
    }
  },
  props: {
    navPosition: {
      type: String,
      default: 'left'
    },
    fieldIdName: {
      default: '_id'
    },
    formData: {},
    moduleName: {
      type: String,
      default: ''
    }
  },
  methods: {
    goToSettingPage() {
     if(confirm('Are yuo sure?')) {
       this.$router.push({ name: 'settings' });
     }
    },
    async saveFormData() {
      const model = new AbModel();
      model.setDynModuleName(this.moduleName);
      await model.getModuleFieldsInfo();

      const previouslyFieldsState = model.dynFields();

      const moduleData = Object.assign({}, this.currentModule);
      moduleData.relatesTo = fieldsMapping.dropdownOptionsToDropdownDbArray(this.currentModule.relatesTo);
      moduleData.dropDownValues = {};
      moduleData.fields = {};
      moduleData.sections = {};

      this.fields.forEach(sectionsFields => {
        for (const sectionColumn in sectionsFields) {
          sectionsFields[sectionColumn].forEach(formField => {
            const dbField = Object.assign(fieldsMapping.defaultDbEditableField(), formField);
            dbField.max = Number(dbField.max);
            dbField.min = Number(dbField.min);
            const fieldName = dbField.name;
            if (formField.dropdown.options.length) {
              moduleData.dropDownValues[fieldName] = fieldsMapping.dropdownOptionsToDropdownDbArray(formField.dropdown);
            }
            if (dbField.query !== undefined) {
              dbField.query.forEach(q => {
                if (!q.value.length) {
                  delete q.value;
                }
                if (!q.relatedTo.length) {
                  delete q.relatedTo;
                }
              });
              if (!dbField.query.length) {
                delete dbField.query;
              }
            }
            if (dbField.relatedFieldCopy !== undefined) {
              const dbRelatedFieldCopy = {};
              dbField.relatedFieldCopy.options.forEach(opt => {
                dbRelatedFieldCopy[opt.label] = [opt.label]
              });
              delete dbField.relatedFieldCopy;
              dbField.relatedFieldCopy = dbRelatedFieldCopy;
            }
            delete dbField.dropdown;
            delete dbField.formData;
            dbField.type = fieldsMapping.getCamelFieldTypeFromSnakeCaseType(formField.type);
            const previouslyFieldState = previouslyFieldsState[fieldName] !== undefined ?  previouslyFieldsState[fieldName] : {};
            moduleData.fields[fieldName] = Object.assign(previouslyFieldState, dbField);
          })
        }
      })

      this.formData.unSectionsFields.forEach(unSectionField => {
        moduleData.fields[unSectionField.name] = unSectionField;
      });

      this.sections.forEach(section => {
        moduleData.sections[section.name] = Object.assign({}, section);
        moduleData.sections[section.name]['groups'] = fieldsMapping.dropdownOptionsToDropdownDbArray(section.groups);
      })
      try {
        const table = "tenants/" + store.state.tenantID + "/modules/";
        db.collection(table)
          .doc(this.moduleName)
          .update(moduleData)
          .then(() => {
            this.$store.dispatch('showAlertMessage', 'update');
          }).catch(() => {
          this.$store.dispatch('showAlertMessage', 'error');
        });
      } catch (e) {
        this.$store.dispatch('showAlertMessage', 'error');
      }
    },

    deleteSection(sIdx) {
      if (confirm('Are you sure?')) {
        this.sections.splice(sIdx, 1);
        this.fields.splice(sIdx, 1);
      }
    },
    createField(item) {
      const newField = fieldsMapping.defaultFormField();
      newField.type = item.type;
      newField.label = item.label;
      if (newField.type === fieldsMapping.SNAKE_RELATED_FIELD) {
        newField.query = [];
      }
      newField.name = item.label.toLowerCase();
      return newField;
    },
    deleteField(idx, sIdx, column) {
      this.currentField = false
      this.tab = 'add'
      this.$delete(this.fields[sIdx][column], idx)
      this.fields[sIdx][column] = this.fields[sIdx][column].map((field, ind) => {field.order = ind + 1; return field;});
    },
    duplicateField (idx, sIdx, column) {
      const newField = extend(true, {}, this.fields[sIdx][column][idx])
      newField.ID = uid()
      delete newField[this.fieldIdName]
      this.fields[sIdx][column].push(newField)
      newField.order = this.fields[sIdx][column].length;
      this.selectForEdit(newField, this.fields[sIdx][column], false)
    },
    onChangeSection(evt) {
      if (evt.moved) {
        this.cardClick(evt.moved.element.name)
        this.sections = this.sections.map((field, ind) => {field.order = ind + 1; return field;});

        const newSIdx = evt.moved.newIndex;
        const oldSIdx = evt.moved.oldIndex;

        const newSectionFields = this.fields[newSIdx];
        this.fields[newSIdx] = this.fields[oldSIdx];
        this.fields[oldSIdx] = newSectionFields;
      }
    },
    onChange(evt, sIdx, column) { //where
      this.isDragAction = true;
      if (evt.added) {
        // evt.added.element - from
        const fromCol = evt.added.element.column;
        const fromSecIdx = this.getSectionIdxFromField(evt.added.element);

        const sectionName = this.sections[sIdx]['name'];
        evt.added.element.section = sectionName;
        evt.added.element.column = column;
        evt.added.element.order = evt.added.newIndex + 1;
        this.fields[sIdx][this.getStringColumnVal({column: column})] =
          this.fields[sIdx][this.getStringColumnVal({column: column})]
            .map((field, ind) => {
              field.order = ind + 1;
              field.section = sectionName;
              field.column = column;
              return field;
            });

        if (fromSecIdx) {
          this.fields[fromSecIdx][this.getStringColumnVal({column: fromCol})] =
            this.fields[fromSecIdx][this.getStringColumnVal({column: fromCol})]
              .map((field, ind) => {
                field.order = ind + 1;
                return field;
              });
        }

        this.selectForEdit(evt.added.element, this.fields[sIdx][this.getStringColumnVal(evt.added.element)], false);
      }
      if (evt.moved) {
        evt.moved.element.order = evt.moved.newIndex + 1;
        this.fields[sIdx][this.getStringColumnVal({column: column})] =
          this.fields[sIdx][this.getStringColumnVal({column: column})]
            .map((field, ind) => {field.order = ind + 1; return field;});
        this.selectForEdit(evt.moved.element, this.fields[sIdx][this.getStringColumnVal(evt.moved.element)], false);
      }
      setTimeout(function (me) {me.isDragAction = false}, 500, this);
    },
    onAddFieldClick(sourceField) {
      const field = this.createField(sourceField)
      field.column = 1;
      field.section = this.currentSection.name;
      this.updateList(field, this.first, true);
    },
    onAddSectionClick(sec) {
      const sectionOrder = this.sections.length ? (this.sections.slice(-1)[0]['order'] + 1) : 1;
      const name = 'Section-' + sectionOrder;
      const newSection = fieldsMapping.defaultSection();
      newSection.order = sectionOrder;
      newSection.name = name;
      newSection.label = sec.label;
      newSection.type = sec.type;
      newSection.groups.options = newSection.groups;
      this.sections.push(newSection);
      this.fields.push(fieldsMapping.getEmptySections());
      this.isClickForCurrentField = true;
    },
    selectForEdit(field, currentList, isClickForCurrentField) {
      this.isClickForCurrentField = isClickForCurrentField;
      this.currentListLength = currentList.length;
      this.currentField = field;
      this.tab = 'edit'
      this.resetClickOnField();
    },
    isSelectedForEdit(idx, sIdx, block) {
      return this.currentField.ID === this.fields[sIdx][block][idx].ID
    },
    cardClick(name) {
      this.sections.forEach(section => {
        if(section.name === name) {
          this.currentSection = section;
          this.isNewSectionSelect = true;
        }
      });
    },
    resetClickOnField() {
      setTimeout(function (me) {me.isClickForCurrentField = false}, 200, this);
    },
    updateList(field, columnName, isClickForCurrentField) {
      const sIdx = this.getSectionIdxFromField(field);
      field.order = this.fields[sIdx][columnName].length + 1;
      this.fields[sIdx][columnName].push(field);
      this.selectForEdit(field, this.fields[sIdx][columnName], isClickForCurrentField);
    },
    getStringColumnVal(searchField) {
      return fieldsMapping.getStringColumnVal(searchField);
    },
    deleteFieldFromList(sectionName, columnInt, beforeUpdatedField) {
      const columnString = this.getStringColumnVal({column: columnInt});
      const sIdx = this.getSectionIdxFromField(beforeUpdatedField);
      this.fields[sIdx][columnString].forEach((f, index) => {
        if (JSON.stringify(f) === JSON.stringify(beforeUpdatedField)) {
          this.deleteField(index, sIdx, columnString);
        }
      })
    },
    emulateDrag(field) {
      const columnName = this.getStringColumnVal(field);
      const sIdx = this.getSectionIdxFromField(field);
      this.selectForEdit(field, this.fields[sIdx][columnName], false);
      this.updateList(field, columnName, false);
    },
    getSectionIdxFromField(field) {
      let sIdx = undefined;
      this.sections.forEach((s, idx) => {
        if (s.name === field.section) {
          sIdx = idx;
        }
      });
      return sIdx;
    },
    updateCurrentFieldCustomHandleRequire(newVal, oldVal) {
      if (newVal === undefined || oldVal === undefined || this.isDragAction) {
        return false;
      }
      if (this.isClickForCurrentField) {
        this.resetClickOnField();
        return false;
      }
      return true;
    }
  },
  watch: {
    fieldsProps(val) {
      this.fields = val
    },
    sectionsProps(val) {
      this.sections = val;
      this.isNewSectionSelect = true;
      this.currentSection = val.length ? val[0] : false;
    },
    moduleProps(val) {
      this.currentModule = val;
      const relatesToOptions = [];
      if (this.currentModule.relatesTo) {
        this.currentModule.relatesTo.forEach(opt => relatesToOptions.push({label: opt, checked: false}));
      }
      this.currentModule.relatesTo = {options: relatesToOptions};
    },
    'currentField.section': {
      handler(newVal, oldVal) {
        if (!this.updateCurrentFieldCustomHandleRequire(newVal, oldVal)) {
          return true;
        }
        const field = this.currentField;
        field.section = oldVal;
        this.deleteFieldFromList(oldVal, this.currentField.column, field);
        field.section = newVal;
        this.emulateDrag(field);
      }
    },
    'currentField.column': {
      handler(newVal, oldVal) {
        if (!this.updateCurrentFieldCustomHandleRequire(newVal, oldVal)) {
          return true;
        }
        const field = this.currentField;
        field.column = oldVal;
        this.deleteFieldFromList(field.section, oldVal, field);
        field.column = newVal;
        this.emulateDrag(field);
      }
    },
    'currentField.order': {
      handler(newVal, oldVal) {
        if (!this.updateCurrentFieldCustomHandleRequire(newVal, oldVal)) {
          return true;
        }
        const field = this.currentField;
        field.order = oldVal;
        this.deleteFieldFromList(field.section, field.column, field);
        field.order = newVal;
        const columnName = this.getStringColumnVal(field);
        const sIdx = this.getSectionIdxFromField(field);
        this.fields[sIdx][columnName].splice(field.order - 1, 0, field);
        this.selectForEdit(field, this.fields[sIdx][columnName], false);
      }
    },
    'currentSection.name': {
      handler(newVal) {
        if (this.isNewSectionSelect) {
          setTimeout(function (me) {me.isNewSectionSelect = false}, 200, this);
          return true;
        }
        this.isClickForCurrentField = true;
        let sIdx = null;
        this.sections.forEach((sec, i) => {if (sec.name === newVal) {sIdx = i}});
        for (const secCol in fieldsMapping.getEmptySections()) {
          this.fields[sIdx][secCol].forEach(field => field.section = newVal);
        }
        this.resetClickOnField();
      }
    },
    'currentSection.order': {
      handler(newVal, oldVal) {
        if (this.isNewSectionSelect) {
          setTimeout(function (me) {me.isNewSectionSelect = false}, 200, this);
          return true;
        }
        this.isClickForCurrentField = true;
        const newSIdx = newVal - 1;
        const oldSIdx = oldVal - 1;

        const newSectionFields = this.fields[newSIdx];
        this.fields[newSIdx] = this.fields[oldSIdx];
        this.fields[oldSIdx] = newSectionFields;

        const newSectionVal = this.sections[newSIdx];
        this.sections[newSIdx] = this.sections[oldSIdx];
        this.sections[oldSIdx] = newSectionVal;
        this.resetClickOnField();
      }
    },
  },
  computed: {
    orderOptions() {
      const options = [];
      for (let i= 0; i < this.currentListLength; i++) {
        options.push(i + 1);
      }
      return options;
    },
    sectionOrderOptions() {
      const options = [];
      for (let i= 0; i < this.sections.length; i++) {
        options.push(i + 1);
      }
      return options;
    },
    sectionsProps() {
      return this.formData.sections ?? [];
    },
    fieldsProps() {
      return this.formData.fields ?? [];
    },
    moduleProps() {
      return this.formData.module.dynModuleData ?? {};
    },
  },
}

