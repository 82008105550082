//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BaseExtend from "@/components/Base/Mixin/BaseExtend";
import BaseModalForms from '@/components/Base/Mixin/BaseModalForms';
import AbModel from '@/components/Models/AbModel';
import store from "@/store";
import AbSubRecordModel from "@/components/Models/AbSubRecordModel";
import { EventBus } from "@/event-bus";
import {uuid} from 'vue-uuid';

export default {
  name: "BaseFieldRelated",
  components: { BaseModalForms },
  mixins: [ BaseExtend ],
  data() {
    return {
      showForm: false,
      relatedOptions: [],
      extendRecordsByCopy: true,
      relatedCopyValue: null,
      showExtendDialog: false,
      unsubscribeData: null,
    };
  },
  async beforeMount() {
    EventBus.$on('needRelatedCopyForNewForm', async (data) => {
      if (this.value && this.value.ID && data.module === this.formData.module) {
        const model = new AbModel();
        const val = await model.getByID(this.value.ID, this.relatedModule);
        this.doRelatedFieldCopy(val);
      }
    });
    await this.getRelatedFieldData();
  },
  methods: {
    async getRelatedFieldData() {
      if (this.relatedQuery) {
        await this.loadRelatedFieldQueryOptions();
      } else {
        this.relatedOptions = this.formData.relatedFields[this.relatedModule];
      }
    },
    addClick() {
      this.showForm = true;
    },
    resetModalForm() {
      this.showForm = false;
    },
    async modalFormSave(id) {
      let newVal = (this.options || this.relatedOptions).find((e) => e.ID === id);
      if (!newVal) {
        newVal = await new AbModel().getByID(id, this.relatedModule);
        this.subscribeModelData(id);
      }
      this.model = newVal;
    },
    async subscribeModelData(id) {
      console.log(id);
      this.unsubscribeData = await new AbModel().subscribeDoc(id, this.relatedModule, this.onDocumentDataChanged);
    },
    onDocumentDataChanged(data) {
      console.log(data);
      this.model = data;
    },
    onFieldChange(val) {
      this.relatedCopyValue = val;
      if (this.askExtendOrDelete(val)) {
        this.showExtendDialog = true;
      } else {
        this.doRelatedFieldCopy(val);
      }
    },
    isDefaultValueEmpty() {
      //const empty = value === undefined || value === null || value === '' || (value.hasOwnProperty('ID') && !value.ID);
      // always replace by copy
      return true;
    },

    askExtendOrDelete(val) {
      //console.log(val);
      if (!val.ID || !this.formData.data.ID) {
        return false;
      }
      if (this.relatedFieldCopy) {
        for (const relCopy in this.relatedFieldCopy) {
          const fieldType = this.getRelatedFieldType(relCopy, this.relatedModule);
          if (fieldType === 'subForm' || fieldType === 'usageSection') {
            return true;
          }
        }
      }
      return false;
    },

    doRelCopyExtend() {
      this.extendRecordsByCopy = true;
      this.doRelatedFieldCopy(this.relatedCopyValue)
    },

    doRelCopyDelete() {
      this.extendRecordsByCopy = false;
      this.doRelatedFieldCopy(this.relatedCopyValue)
    },

    // Related field copy functions
    async doRelatedFieldCopy(val) {
      if (this.relatedFieldCopy) {
        //console.log('doRelatedFieldCopy');
        for (const relCopy in this.relatedFieldCopy) {
          const fieldType = this.getRelatedFieldType(relCopy, this.relatedModule);
          if (fieldType) {
            if (this.relatedFieldCopy[relCopy].length) {
              for (const relCopyField in this.relatedFieldCopy[relCopy]) {
                const dataField = this.relatedFieldCopy[relCopy][relCopyField];
                const targetType = this.getFieldType(dataField);
                if (fieldType === 'subForm') {
                  if (targetType === 'usageSection') {
                    if (!this.formData.data.ID) {
                      this.formData.callbackAfterSave = async (id) => {
                        this.formData.data.ID = id;
                        this.copySubFormValue(dataField, val, relCopy);
                        this.formData.callbackAfterSave = null;
                        this.saveTheForm(false);
                      }
                      this.saveTheForm(false);
                    }
                    else {
                      this.copySubFormValue(dataField, val, relCopy);
                    }
                  } else {
                    this.copySubFormValue(dataField, val, relCopy);
                  }
                } else if (fieldType === 'usageSection') {
                  if (targetType === 'usageSection') {
                    if (!this.formData.data.ID) {
                      this.formData.callbackAfterSave = async (id) => {
                        this.formData.data.ID = id;
                        this.copyUsageSectionValue(dataField, val, relCopy);
                        this.formData.callbackAfterSave = null;
                        this.saveTheForm(false);
                      }
                      this.saveTheForm(false);
                    }
                    else {
                      this.copyUsageSectionValue(dataField, val, relCopy);
                    }
                  } else {
                    this.copyUsageSectionValue(dataField, val, relCopy);
                  }
                } else {
                  if (val[relCopy] !== undefined) {
                    this.copyRelatedValue(dataField, val, relCopy);
                  }
                }
              }
            }
          }

        }
        await this.cascadeRelateCopy();
      }
    },

    async deleteSubRecords(dataField) {
      const modelSubRecordRelated = new AbSubRecordModel();
      modelSubRecordRelated.setMainData({
        documentID: this.formData.data.ID,
        subRecord: dataField,
        module: this.formData.module
      });
      await modelSubRecordRelated.delSubRecords();
    },

    async copySubFormValue(dataField, val, relCopy) {
      const targetType = this.getFieldType(dataField);
      if (!val || !val[relCopy]) {
        return null;
      }
      if (targetType === 'subForm') {
        // TODO check target sub form fields
        if (!this.extendRecordsByCopy) {
          this.formData.data[dataField] = [];
        }
        this.formData.data[dataField] = this.formData.data[dataField].concat(val[relCopy].map(e => {
          e.ID = uuid.v1();
          return e;
        }));
      }
      if (targetType === 'usageSection') {
        if (!this.extendRecordsByCopy) {
          await this.deleteSubRecords(dataField);
        }
        const modelSubRecord = new AbSubRecordModel();
        modelSubRecord.setMainData({
          documentID: this.formData.data.ID,
          subRecord: dataField,
          module: this.formData.module
        });
        val[relCopy].map(e => {
          e.ID = '';
          modelSubRecord.save(e).then(() => {
            EventBus.$emit('needUsageSectionRefresh', dataField);
          });
        });
      }
    },

    async copyUsageSectionValue(dataField, val, relCopy) {
      if (!val || !val.ID) {
        return null;
      }
      const modelSubRecordRelated = new AbSubRecordModel();
      modelSubRecordRelated.setMainData({
        documentID: val.ID,
        subRecord: relCopy,
        module: this.relatedModule
      });
      const subRecords = await modelSubRecordRelated.getSubRecords();
      const targetType = this.getFieldType(dataField);
      if (targetType === 'subForm') {
        if (!this.extendRecordsByCopy) {
          this.formData.data[dataField] = [];
        }
        this.formData.data[dataField] = this.formData.data[dataField].concat(subRecords.map(e => {
          e.ID = uuid.v1();
          return e;
        }));
      }
      if (targetType === 'usageSection') {
        if (!this.extendRecordsByCopy) {
          await this.deleteSubRecords(dataField);
        }
        const modelSubRecord = new AbSubRecordModel();
        modelSubRecord.setMainData({
          documentID: this.formData.data.ID,
          subRecord: dataField,
          module: this.formData.module
        });
        subRecords.map(e => {
          e.ID = '';
          modelSubRecord.save(e).then(() => {
            EventBus.$emit('needUsageSectionRefresh', dataField);
          });
        });
      }
    },

    getFieldType(dataField) {
      const dmodule = this.formData;
      const field = dmodule.dynamicFields[dataField] ?? dmodule.dynamicSections[dataField];
      if (field) {
        return field.type;
      } else {
        return '';
      }
    },

    getRelatedFieldType(relCopy, module) {
      const dmodule = store.state.dynamicModules[module];
      if (!dmodule) {
        return null;
      }
      const field = dmodule.fields[relCopy] ?? dmodule.sections[relCopy];
      return field ? field.type : '';
    },

    async cascadeRelateCopy() {
      // TODO add 3-4 loop for all fields or use event bus
      for (const f in this.formData.dynamicFields) {
        const field = this.formData.dynamicFields[f];
        if (f !== this.name && field.relatedFieldCopy) {
          let val = this.formData.data[f];
          if (val && val.ID) {
            val = await new AbModel().getByID(val.ID, field.relatedModule);
            for (const relCopy in field.relatedFieldCopy) {
              if (val[relCopy] !== undefined) {
                if (field.relatedFieldCopy[relCopy].length) {
                  for (const relCopyField in field.relatedFieldCopy[relCopy]) {
                    const dataField = field.relatedFieldCopy[relCopy][relCopyField];
                    this.copyRelatedValue(dataField, val, relCopy);
                  }
                }
              }
            }
          }
        }
      }
    },

    async setMainRelatedFieldIfEmpty() {
      if (this.relatedToByQuery) {
        const relatedTo = this.relatedToByQuery.relatedTo;
        const relatedToField = this.relatedToByQuery.field;
        if (this.formData.data[this.name].ID && this.formData.data[relatedTo] && !this.formData.data[relatedTo].ID) {
          const fullField = await new AbModel().getByID(this.formData.data[this.name].ID, this.relatedModule);
          if (fullField && fullField[relatedToField]) {
            const val = fullField[relatedToField];
            this.formData.data[relatedTo] = val;
            await this.cascadeRelateCopy();
          }
        }
      }
    },

    copyRelatedValue(toVal, value, fromVal) {
      if (this.isDefaultValueEmpty(this.formData.data[toVal])) {
        const copyValue = value[fromVal] ?? null;
        this.formData.data[toVal] = copyValue;
        EventBus.$emit('needToUpdateFieldValueAfterCopy', {name: toVal, value: copyValue});
      }
    },

    async loadRelatedFieldQueryOptions() {
      this.relatedQuery.forEach((f) => {
        if (f.relatedTo) {
          const relatedID = this.formData.data[f.relatedTo] ? this.formData.data[f.relatedTo].ID : null;
          if (relatedID && (f.relatedID !== relatedID)) {
            f.relatedID = relatedID;
          }
        }
      });
      const param = {
        query: this.relatedQuery,
        relatedModule: this.relatedModule
      };
      this.relatedOptions = await this.queryRelatedFieldOptions(param);
    },

    saveTheForm(needCallbackAlert = true) {
      this.$emit('saveTheForm', needCallbackAlert);
    }

  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.copyCurrentValue = val;
        this.onFieldChange(val);
        const relVal = { ID: val.ID, name: val.name };
        this.$emit('input', relVal)
      }
    },
    fname() {
      return this.formName || this.name;
    },
    rowOptions () {
      const result = this.soptions || []
      const field = this.formData.dynamicFields ? this.formData.dynamicFields[this.name] : {};
      result.forEach(e => {
        if (field) {
          if (field.hasOwnProperty('primaryResultField')) e.optionsRow1 = e[field.primaryResultField];
          if (field.hasOwnProperty('secondaryResultField')) e.optionsRow2 = e[field.secondaryResultField];
        }
      })
      return result
    }
  },
  watch: {
    refreshFieldOnChange() {
      if (this.refreshFieldOnChange) {
        this.doRelatedFieldCopy(this.value);
      }
    },
    'formData.data': {
      handler() {
        if (this.relatedQuery) {
          this.loadRelatedFieldQueryOptions();
          this.setMainRelatedFieldIfEmpty();
        }
      },
      deep: true
    }
  }
};
