//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseExtend from "@/components/Base/Mixin/BaseExtend";
import BaseModalForms from '@/components/Base/Mixin/BaseModalForms'
import { mapActions, mapGetters, mapState } from 'vuex'
import ListValues from '@/components/Mixin/ListValues'
import FormListMixin from "@/components/Mixin/FormListMixin";
import AbModel from '@/components/Models/AbModel';
import BaseRelatedMixin from "@/components/Base/Mixin/BaseRelatedMixin";
import ListViewSetting from "@/components/Parts/ListViewSetting";

export default {
  name: "BaseSectionRelatedRecords",
  components: { BaseModalForms, ListViewSetting },
  mixins: [ BaseExtend, FormListMixin, BaseRelatedMixin ],

  data() {
    return {
      loading: false,
      separator: "horizontal",
      localPagination: {
        sortBy: "changedAt",
        descending: true,
      },
      selected: [],
      deleteBtnVisible: false,
      isPaginationReceived: false,
      data: [],
      showForm: false,
      modalFormID: null,
      deleteFormsModal: false
    };
  },
  methods: {
    ...mapActions('settings', ["getListSettings", "setListSettings"]),
    addClick() {
      this.modalFormID = null;
      this.showForm = true;
    },
    resetModalForm() {
      this.modalFormID = null;
      this.showForm = false;
    },
    async modalFormSave() {
      this.refreshList();
    },
    deleteForms() {
      this.selected.forEach((e) => {
        new AbModel().delete(e.ID, this.relatedModule)
        .then(() => {
          this.refreshList();
        });
      });
      this.selected = [];
    },
    loadRelatedData() {
      const relatedID = this.formData.data.ID;
      if (!relatedID) {
        return [];
      }
      //const relatedID = this.formData.data.customer.ID;
      const param = {
        query: [{relatedTo: this.relatedModule, relatedID: relatedID, field: this.relatedByField}],
        relatedModule: this.relatedModule
      };
      //console.log();
      this.queryRelatedFieldOptions(param).then((res) => {
        this.data = ListValues.transform(res, this.dynFields);
      });
    },
    async onRequest() {
      // nothing
    },
    refreshList() {
      this.localPagination.page = 1;
      this.onRequest({
        pagination: this.localPagination,
        filter: this.filter,
      });
      this.loadRelatedData();
    },
    savePagination(pagination) {
      if (this.isPaginationReceived) {
        if (this.filtersSelectModel) {
          this.filtersSelectModel.pagination = {...pagination};
        }
      }
    },
    onRowClick(evt, row) {
      this.modalFormID = row.ID;
      this.showForm = true;
    },
    onRowDblClick(evt, row) {
      this.modalFormID = row.ID;
      this.showForm = true;
    },

    getSelectedString() {
      return this.selected.length === 0
        ? ""
        : `${this.selected.length} record${
          this.selected.length > 1 ? "s" : ""
        } ${this.$t("table.misc.selectedOf")} ${this.data.length}`;
    },
    getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
      return `${firstRowIndex}-${endRowIndex} ${this.$t(
        "table.misc.of"
      )} ${totalRowsNumber}`;
    },
  },
  beforeMount() {
    this.refreshList();
  },
  watch: {
    selected() {
      this.$emit("onSelected", this.selected);
    },
    togleRefreshList() {
      this.refreshList();
    },
    localPagination() {
      if (this.localPagination.oldRowsPerPage !== this.localPagination.rowsPerPage) {
        this.savePagination(this.localPagination);
        this.localPagination.oldRowsPerPage = this.localPagination.rowsPerPage;
      }
    },
  },
  computed: {
    ...mapState("settings", ["listSettings"]),
    dynModuleName() {
      return this.relatedModule;
    },
  }
};
