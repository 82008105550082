import { render, staticRenderFns } from "./MediaList.vue?vue&type=template&id=dc054b62&scoped=true&"
import script from "./MediaList.vue?vue&type=script&lang=js&"
export * from "./MediaList.vue?vue&type=script&lang=js&"
import style0 from "./MediaList.vue?vue&type=style&index=0&id=dc054b62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc054b62",
  null
  
)

export default component.exports
import {QCarousel,QCarouselSlide,QCarouselControl,QBtn,QUploader} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCarousel,QCarouselSlide,QCarouselControl,QBtn,QUploader})
