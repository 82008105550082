//
//
//
//
//
//
//
//
//

import BaseFieldAutoNumber from '../../Base/BaseFieldAutoNumber'
import BaseFieldCheckbox from '@/components/Base/BaseFieldCheckbox'
import BaseFieldCurrency from '@/components/Base/BaseFieldCurrency'
import BaseFieldDate from '../../Base/BaseFieldDate'
import BaseFieldDateTime from '../../Base/BaseFieldDateTime'
import BaseFieldText from '../../Base/BaseFieldText'
import BaseFieldDecimal from '@/components/Base/BaseFieldDecimal'
import BaseFieldDropDown from '../../Base/BaseFieldDropDown'
import BaseFieldLargeText from '@/components/Base/BaseFieldLargeText'
import BaseFieldNumber from '@/components/Base/BaseFieldNumber'
import BaseFieldPercentage from '@/components/Base/BaseFieldPercentage'
import BaseFieldRelated from '../../Base/BaseFieldRelated'
import BaseFieldRelatedMultiSelect from '../../Base/BaseFieldRelatedMultiSelect'



export default {
  name: 'EditableElement',
  // eslint-disable-next-line vue/no-unused-components
  components: { BaseFieldText, BaseFieldDate, BaseFieldDateTime, BaseFieldRelated, BaseFieldRelatedMultiSelect, BaseFieldDropDown, BaseFieldAutoNumber,
    // eslint-disable-next-line vue/no-unused-components
    BaseFieldCheckbox, BaseFieldCurrency, BaseFieldDecimal, BaseFieldLargeText, BaseFieldNumber, BaseFieldPercentage},
  props: ['value'],
  methods: {
    onClick: function () {
      this.$emit('click', this.value)
    }
  },
  computed: {
    element: function () {
      const nameParts = this.value.type.split('_')
      for (let i = 0; i < nameParts.length; i++) {
        nameParts[i] = nameParts[i].charAt(0).toUpperCase() + nameParts[i].slice(1)
      }
      return nameParts.join('')
    }
  }
}
