//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "BaseModalForms",
  props: { name: null , formData: null, modalFormSave: null, resetModalForm: null,  showForm: null, relatedModule:null,
    modalFormID: null, lineForm: null, sectionLabel: null, lineSection: null },
  components: {
    DynamicForm: () => import('@/components/Forms/DynamicForm'),
    DynamicRelated: () => import('@/components/Forms/DynamicRelated')
  },

  data() {
    return {
      dynamicShowModalForm: false,
    };
  },
  methods: {
    saveTheForm(needCallbackAlert = true) {
      this.$emit('saveTheForm', needCallbackAlert);
    }
  },
  computed: {
    nameForm() {
      const analog = {
        customer: 'account',
        owner: 'user',
      };
      return analog[this.name] || this.name;
    }
  },
  watch: {
    showForm(val) {
      if (this.relatedModule) {
        this.dynamicShowModalForm = val;
      } else {
        this[this.nameForm + 'ShowModalForm'] = val;
      }
    }
  }
};
