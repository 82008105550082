






















































































import { Component, Vue, Prop } from "vue-property-decorator";
import Dropzone from "@/components/Nylas/Dropzone.vue";

@Component({
  components: { Dropzone }
})
export default class EmailAttachments extends Vue {
  @Prop(Object) data;

  files = [];

  mounted() {
    this.files = this.data.attachments.concat(this.data.media);
  }

  addFiles(data) {
    this.$emit("addFiles", data);
  }

  removedFile(data) {
    this.$emit("removedFile", data);
  }

  addToDropZone(file) {
    const addFile = { ...file, ...{ size: file.fileSize, name: file.fileName }};
    const url = file.downloadableURL;
    // eslint-disable-next-line
    // @ts-ignore: Unreachable code error
    this.$refs.EmailAttachDropZone.addToDropZone(addFile, url);
  }
}
