

















































  import { Vue, Component, Prop, Watch } from "vue-property-decorator";
  import { date } from "quasar";

  import functions from '../../functions';
  import db from "../../db";
  import store from "../../store";

  interface SenderDto {
    name: string;
    email: string;
  }

  interface RecipientDto {
    name: string;
    email: string;
  }

  interface FileDto {
    id: string;
    filename: string;
    contentType: string;
    size: number;
}

  interface MessageDto {
    id: string;
    to: Array<RecipientDto>;
    from: Array<SenderDto>;
    subject: string;
    body: string;
    files: Array<FileDto>;
    date: Date;
    unread: boolean;
  }

  @Component({})
  export default class EmailMessages extends Vue {
    @Prop(String) email;
    @Prop(String) threadID;

    emailSettings: Array<any> = [];

    messages: Array<MessageDto>|null = null;
    showMessageDetailsModal = false;
    openedMessage: MessageDto|null = null;

    loading = false;
    pagination = {
      sortBy: 'desc',
      descending: false,
      page: 1,
      rowsPerPage: 5,
      rowsNumber: 1
    };

    @Watch('threadID')
    async searchHandler() {
      await this.getMessages();
    }

    async getMessages(props: any = null): Promise<void> {
      const emailSettings: any = this.emailSettings.find((el: any) => el.allowedUsers.includes(store.state.userID));

      if(!emailSettings || (!this.email && !this.threadID)) {
        this.messages = [];
        return;
      }

      const getMessages = functions.httpsCallable('getMessages');

      const options: any = {
        in: 'inbox',
        'any_email': this.email,
        limit: this.pagination.rowsPerPage,
        offset: (this.pagination.page - 1) * this.pagination.rowsPerPage
      };

      if(this.threadID) {
        options.threadId = this.threadID;
      }

      if(props) {
        const { page, rowsPerPage, sortBy, descending } = props.pagination;
        const filter = props.filter;

        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;

        options.limit = rowsPerPage;
        options.offset = (page - 1) * rowsPerPage;
      }

      this.loading = true;
      getMessages({
        accessToken: emailSettings.nylasAccessToken,
        options,
      }).then((res: any) => {
        const { count, messages } = res.data;

        this.pagination.rowsNumber = count;
        this.messages = messages;
        this.loading = false;
      }).catch(error => {
        console.log('error: ', error);
        this.messages = [];
      });
    }

    async downloadFile(file: FileDto): Promise<void> {
      const emailConfig = (this as any).userSettings.emails;
      const downloadFile = functions.httpsCallable('downloadFile');
      downloadFile({
        accessToken: emailConfig.nylasAccessToken,
        options: {
          id: file.id,
        }
      }).then((res: any) => {
        const byteArray = new Uint8Array(res.data);
        const fileURL = window.URL.createObjectURL(new Blob([byteArray]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', file.filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(error => {
        console.log('error: ', error);
      });
    }

    toggleMessageDetailsModal(event: any, row: MessageDto|any): void {
      if(row) {
        this.openedMessage = row;
      }
      this.showMessageDetailsModal = !this.showMessageDetailsModal;
    }

    accountArrayToString(account: Array<SenderDto|RecipientDto>): string {
      return account && account
        .map((el: SenderDto|RecipientDto) =>  (el.name || el.email) ? `${el.name} - ${el.email}` : el)
        .join(', ');
    }

    async getEmailSettings() {
      await db
        .collection(`/tenants/${store.state.tenantID}/settings/email/addresses`)
        .get()
        .then((resp) => {

          if (resp && typeof resp !== "undefined") {
            const result: any[] = [];

            resp.forEach((r: any) => {
              const pushObj = r.data();
              pushObj.id = r.id;
              result.push(pushObj);
            });

            this.emailSettings = result;
          }

        })
        .catch(() => {
          this.emailSettings = [];
        });
    }

    async mounted() {
      await this.getEmailSettings();
      // await this.getMessages();
    }

    get columns(): Array<any> {
      return [
        {
          name: "id",
          align: "left",
          label: "ID",
          field: "id",
          sortable: true,
        },
        {
          name: "from",
          align: "left",
          label: "Sender",
          field: (row) => `${row.from[0].name} - ${row.from[0].email}`,
          sortable: false,
        },
        {
          name: "to",
          align: "left",
          label: "Recipient",
          field: (row) => `${row.to[0].name} - ${row.to[0].email}`,
          sortable: false,
        },
        {
          name: "subject",
          align: "left",
          label: "Subject",
          field: "subject",
          sortable: false,
        },
        {
          name: "unread",
          align: "left",
          label: "Unread",
          field: "unread",
          sortable: false,
        },
        {
          name: "date",
          align: "left",
          label: "Date",
          field: (row) =>
              date.formatDate(row.date, "YYYY-MM-DD HH:mm"),
          sortable: false,
        }
      ];
    }

    get dataMessages(): Array<MessageDto> {
      return this.messages || [];
    }
  }
