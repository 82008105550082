//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseExtend from "@/components/Base/Mixin/BaseExtend";
import BaseRelatedMixin from "@/components/Base/Mixin/BaseRelatedMixin";
import BaseModalForms from '@/components/Base/Mixin/BaseModalForms'
import FormListMixin from "@/components/Mixin/FormListMixin";
import ListValues from '@/components/Mixin/ListValues';
import ListViewSetting from "@/components/Parts/ListViewSetting";
import store from '@/store'
import functions from "@/functions";

export default {
  name: "BaseSectionProductLines",
  components: { BaseModalForms, ListViewSetting },
  mixins: [ BaseExtend, FormListMixin, BaseRelatedMixin ],
  data() {
    return {
    };
  },
  methods: {
    loadRelatedData() {
      const relatedID = this.formData.data.ID;
      if (!relatedID) {
        return [];
      }
      const relatedByField = 'record';
      const param = {
        query: [{value: relatedID, field: relatedByField}],
        relatedModule: this.lineModule
      };
      //console.log(param);
      this.queryRelatedFieldOptions(param).then((res) => {
        this.data = ListValues.transform(res, this.dynamicFields);
      });
    },
    getListSettings() {
      return {};
    },
    doCustomButtonAction(buttonAction) {
      const actions = buttonAction.actions.sort((a, b) => {
        return a.order - b.order;
      });
      actions.forEach(action => {
        const runAction = functions.httpsCallable('runAction');
       // functions.useEmulator('localhost', 5001);

        const tenantID = store.state.tenantID;
        const parameters = {};
        parameters.ID = this.formData.data.ID;
        parameters.module = this.formData.module;
        const data = {ID: action.ID, tenantID, type: action.type, parameters};
        runAction(data).then(res => {
          this.refreshList();
          console.log(res);
        })
      });
    },
    saveTheForm(needCallbackAlert = true) {
      this.$emit('saveTheForm', needCallbackAlert);
    }
  },
  computed: {
    lineModule() {
      // 'salesLines', 'invoiceLines', 'generalLines', 'purchaseLines'
      return this.lineType;
    },
    lineSection() {
      return this.section;
    },
    columns() {
      const cols = [];
      const fields = this.dynamicFields;
      for(const i in this.visibleFormListColumns) {
        const column = this.visibleFormListColumns[i];
        if (fields[column]) {
          const newCol = {
            name: column,
            align: this.align,
            label: fields[column] && fields[column].label ? fields[column].label : column,
            order: fields[column] && fields[column].order ? fields[column].order : 0,
            field: column,
            sortable: true,
          };
          if (!fields[column].hidden) {
            cols.push(newCol);
          }
        }
      }
      //console.log('cols', cols);
      return cols;
    },
    dynFields() {
      return this.dynamicFields;
    },
    dynamicFields() {
      return store.state.allModules && store.state.allModules[this.lineModule] ? store.state.allModules[this.lineModule].fields : {};
    },
    customButtonsComps() {
      return this.customButtons || [];
    }
  }
};
