import AbModel, {Fields} from '@/components/Models/AbModel';
import store from '@/store';
import AbSubRecordModel from "@/components/Models/AbSubRecordModel";

export interface FormData {
  form: string;
  module: string;
  data: Fields;
  callbackAfterSave: object | null | Promise<void>;
  callbackAfterDelete: object | null | Promise<void>;
  callbackAlert: boolean;
  callbackAfterSaveRead: object | null | Promise<void>;
  disabledFields: object | null;
  hiddenFields: object | null;
}

export default class FormDataTools {
  module = '';
  model = new AbModel();
  formData = new class FormDataClass implements FormData {
    form = "";
    module = "";
    data = new Fields();
    callbackAfterSave = null;
    callbackAfterDelete = {};
    callbackAlert = false;
    callbackAfterSaveRead: any = null;
    disabledFields = {};
    hiddenFields = {};
    dropDownValues = {};
    relatedFields = {};
    dynamicFields = {};
    dynamicModule = {};
    dynamicSections = {};
    dynAutoNumbers = {};
    unsubscribeData = null;
  };

  constructor(module: string) {
    this.module = module;
    this.model.setDynModuleName(this.module);
  }

  async load(ID = '') {
    await this.model.load(ID);
    this.formData.disabledFields = this.model.disabledFields;
    this.formData.hiddenFields = this.model.hiddenFields;
    this.formData.dynamicFields = this.model.dynFields();
    this.formData.dynamicModule = this.model.dynModule();
    this.formData.dynamicSections = this.model.dynSections();
    this.formData.dynAutoNumbers = this.model.dynAutoNumbers();
    this.formData.dropDownValues = this.model.dropDownValues;

    (this.formData.data as any) = this.getAllFields();

    if (ID) {
      const record = await this.model.getByID(ID, this.module);
      this.formData.data = { ...this.formData.data, ...record };
    }

    // add sub sections
    if (ID) {
      for(const df in this.formData.dynamicSections) {
        if (this.formData.dynamicSections[df].type === 'usageSection') {
          const modelSubRecord = new AbSubRecordModel();
          modelSubRecord.setMainData({
            documentID: ID,
            subRecord: df,
            module: this.module
          });
          const subRecords = await modelSubRecord.getSubRecords();
          this.formData.data[df] = subRecords;
        }
      }
    }

    // add related records
    if (ID) {
      for(const df in this.formData.dynamicSections) {
        const sec = this.formData.dynamicSections[df];
        if (sec.type === 'relatedRecords') {
          this.formData.data[df] = await FormDataTools.loadRelatedData(ID, sec);
        }
      }
    }

    // add relates tables for related fields
    this.formData.relatedFields = store.state.dynamic;
  }

  getFormData() {
    return this.formData;
  }

  getAllFields() {
    const mFields = this.model.fields();
    const dFields = this.convertDynamicFields(this.model.dynFields(), this.model.dynSections());
    return { ...mFields, ...dFields };
  }

  getDefValueForType(ftype) {
    const types = {
      text: '',
      checkbox: false,
      number: null,
      date: null,
      datetime: null,
      dropdown: null,
      relatedField: {ID: '', name: ''},
      noType: null,
      subForm: [],
      relatedMultiSelect: [],
      attachment: [],
      media: [],
    };
    return types[ftype];
  }

  convertDynamicFields(fields, sections) {
    const result = {};

    for(const field in fields) {
      const ftype = fields[field].type || 'noType';
      const tVal = this.getDefValueForType(ftype);
      const value = tVal === false ? false
          : tVal === '' ? ''
              : tVal || null;

      result[field] = fields[field].defaultValue || value;
    }
    // add sections fields
    for(const sec in sections) {
      if (sections[sec].type === 'usageSection') {
        const fn = sections[sec].subRecordField;
        delete result[fn];
      }
      if (sections[sec].type === 'attachments') {
        result[sec] = [];
      }
      if (sections[sec].type === 'media') {
        result[sec] = [];
      }
    }
    return result;
  }

  static async loadRelatedData(relatedID, sec) {
    if (!relatedID) {
      return [];
    }
    const param = {
      query: [{relatedTo: sec.relatedModule, relatedID: relatedID, field: sec.relatedByField}],
      relatedModule: sec.relatedModule
    };
    return await store.dispatch('query/queryRelatedFieldOptions', param);
  }

}
