//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseSubMixin from "@/components/Base/Mixin/BaseSubMixin";
import ListValues from '@/components/Mixin/ListValues'
import {mapActions} from "vuex";
import AbSubRecordModel from "@/components/Models/AbSubRecordModel";
import { EventBus } from "@/event-bus";

export default {
  name: "BaseSectionUsage",
  components: { DynamicSubRecord: () => import('@/components/Forms/DynamicSubRecord') },
  mixins: [ BaseSubMixin ],

  data() {
    return {
      loading: false,
      separator: "horizontal",
      selected: [],
      deleteBtnVisible: false,
      dynamicSubRecordShowModalForm: false,
      modalFormID: null,
      subFormFieldData: {},
      deleteFormsModal: false,
      localPagination: {},
      modelSubRecord: null,
      data: [],
    };
  },
  methods: {
    ...mapActions("query", ["querySubRecords"]),
    addClick() {
      this.modalFormID = null;
      this.dynamicSubRecordShowModalForm = true;
    },
    resetModalForm() {
      this.modalFormID = null;
      this.dynamicSubRecordShowModalForm = false;
    },
    async modalFormSave(ID) {
      this.resetModalForm();
      this.refreshList(ID);
    },

    deleteForms() {
      const modelSubRecord = new AbSubRecordModel();
      modelSubRecord.setMainData({
        documentID: this.formData.data.ID,
        subRecord: this.subRecord,
        module: this.formData.module
      });
      this.selected.forEach((e) => {
        modelSubRecord.delete(e.ID).then(() => {
          this.refreshList();
        });
      });
      this.selected = [];
    },

    async loadRelatedData(ID = null) {
      const docID = this.formData.data.ID || ID;
      if (!this.subRecord || !docID) {
        return [];
      }
      const modelSubRecord = new AbSubRecordModel();
      modelSubRecord.setMainData({
        documentID: this.formData.data.ID,
        subRecord: this.subRecord,
        module: this.formData.module
      });
      const subRecords = await modelSubRecord.getSubRecords();
      this.data = ListValues.transform(subRecords, this.dynamicFields);
    },
    refreshList(ID = null) {
      this.localPagination.page = 1;
      this.loadRelatedData(ID);
    },
    onRowClick(evt, row) {
      this.modalFormID = row.ID;
      this.dynamicSubRecordShowModalForm = true;
    },
    onRowDblClick(evt, row) {
      this.modalFormID = row.ID;
      this.dynamicSubRecordShowModalForm = true;
    },
    saveTheForm(needCallbackAlert = true) {
      this.$emit('saveTheForm', needCallbackAlert);
    }
  },
  beforeMount() {
    this.refreshList();
    EventBus.$on('needUsageSectionRefresh', dataField => {
      if (dataField === this.subRecord) {
        this.refreshList();
      }
    });
  },

  watch: {
    selected() {
      this.$emit("onSelected", this.selected);
    },

    togleRefreshList() {
      this.refreshList();
    },
  },
  computed: {
    subRecord() {
      return this.subRecordField;
    },
    dynamicFields() {
      return this.subFormRecordInfo ? this.subFormRecordInfo.fields : {};
    },
    subFormRecordInfo() {
      return this.formData.dynamicFields[this.subRecordField];
    },
    align() {
      return "left";
    },
  }
};
