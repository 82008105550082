import AbModel, {Fields as AbModelFields, User} from "@/components/Models/AbModel";

class AbSubFormModel extends AbModel {

  async getFieldsInfoDoc() {
    const doc = this.subFormFieldData;
    doc.dynamic = true;
    doc.sections = this.getDynamicSections(doc);
    for(const field in doc.fields) {
      if(!doc.fields[field].section) {
        doc.fields[field].section = doc.section ?? this.subFormField;
      }
    }
    return doc;
  }

  getDynamicSections(doc) {
    const sections = {};
    const secName = doc.section ?? this.subFormField;
    if (secName) {
      sections[secName] = {
        type: 'fieldSection',
        label: doc.formLabel || secName
      };
    }
    return sections;
  }

}

export default AbSubFormModel;
