//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BaseExtend from "@/components/Base/Mixin/BaseExtend";

export default {
  name: "BaseFieldNumber",
  components: {},
  mixins: [ BaseExtend ],
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        if (!val) {
          val = null;
        } else {
          val =  Number(val);
        }
        this.$emit('input', val);
      },
    },
  },
};
