import { render, staticRenderFns } from "./RelatedFieldOptions.vue?vue&type=template&id=78d8c1c6&"
import script from "./RelatedFieldOptions.vue?vue&type=script&lang=js&"
export * from "./RelatedFieldOptions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QSelect,QTable,QBtn,QTooltip,QTr,QTd,QPopupEdit} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSelect,QTable,QBtn,QTooltip,QTr,QTd,QPopupEdit})
